import {request} from "@/utils";
const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const retryRequest = async (requestFn, retries = 5, delayMs = 2000) => {
  for (let attempt = 1; attempt <= retries; attempt++) {
    try {
      return await requestFn();
    } catch (error) {
      console.error(`Attempt ${attempt} failed:`, error);
      if (attempt < retries) {
        await delay(delayMs);
      } else {
        throw error;
      }
    }
  }
};

export const processOneAirbnbMessage = (rawData, mes, setMessageId, roomId) => {
  setMessageId(mes);
  return rawData.result.filter(item => item.result && item.result.length > 0)
    ? rawData.result
        .map((item) => {
          if (!item.result || item.result.length === 0) {
            return null;
          }

          const senderWithReservation = item.result.find(
            (message) => message.sender && message.sender.includes("預訂人")
          );

          const displayName = senderWithReservation
            ? senderWithReservation.sender
            : " · 預訂人";
          const pictureUrl = senderWithReservation
            ? senderWithReservation.sender_photo_url
            : null;

          let lastMessage = null;
          for (let i = item.result.length - 1; i >= 0; i--) {
            if (item.result[i].content) {
              lastMessage = item.result[i];
              break;
            }
          }

          if (!lastMessage) {
            lastMessage = {
              content: "No messages",
              date: "",
              time: "",
            };
          }

          return {
            id: item.last_update_timestamp,
            userId: item.last_update_timestamp || "· 預訂人",
            messageId: mes,
            roomId: roomId,
            displayName: displayName,
            pictureUrl: pictureUrl,
            channel: "airbnb",
            last_two_messages: [
              {
                created_at: lastMessage.time,
                message: lastMessage.content,
              },
            ],
            unreadCount: 1,
          };
        })
        .filter((item) => item !== null)
    : [];
};

export const refreshAirbnb = (fetching, fetchAirbnbClients) => {
  if (!fetching) {
    fetchAirbnbClients(true);
  }
};

const getprofile = async (roomId, setLoading) => {
  try {
    await request.get(`/private/module/rpa/profile/${roomId}`);
    console.warn('Profile fetched successfully');
    return true;
  } catch (error) {
    console.warn('Error fetching profile');
    setLoading(false);
    return false;
  }
};

export const fetchAirbnbClients = async (
  serverUrl,
  room,
  token,
  setClientsAirbnb,
  setLoading,
  setFetching,
  requestQueue,
  setRequestQueue,
  setMessageId,
  roomId,
  setAirbnbMessage,
  setOrderAirbnbMessage
) => {
  setLoading(true);
  setFetching(true);
  const profileSuccess = await getprofile(room.id, setLoading);
  if (!profileSuccess) {
    return; 
  }

  if (requestQueue.length > 0) {
    setRequestQueue(prevQueue => [...prevQueue, { roomId, token }]);
    setLoading(false);
    setFetching(false);
    return;
  }
  
  try {
    // get message_ids
    const getMessageIds = () => request.get(
      `/private/module/rpa/airbnb/message_ids/${roomId}`);

    let idsResponse;
    try {
      idsResponse = await retryRequest(getMessageIds);
    } catch (error) {
      console.warn("GET message_ids 失敗，嘗試 POST 開啟任務。");
      // post message_ids
      const postMessageIds = () => request.post(
        `/private/module/rpa/airbnb/message_ids/${roomId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      try {
        await postMessageIds();
      } catch (postError) {
        console.error("POST message_ids 失敗：", postError);
      }

      // get message_ids again
      try {
        idsResponse = await retryRequest(getMessageIds);
      } catch (finalError) {
        console.error("最終 GET message_ids 失敗：", finalError);
        return;
      }
    }

    if (!idsResponse?.result?.length) {
      console.warn("沒有返回 message IDs。");
      return;
    }

    const messageIds = idsResponse.result;
    let messages = [];

    for (const messageId of messageIds) {
      try {
        // get message
        await delay(2000);
        const getMessage = () => request.get(
          `/private/module/rpa/airbnb/message/${roomId}`,
          {
            params: {
              message_id: messageId,
            },
          }
        );
        // add order info fetching
        fetchAirbnbOrder(roomId, messageId, setOrderAirbnbMessage );
        let messageResponse;
        try {
          messageResponse = await retryRequest(getMessage);
          if (messageResponse?.status === "pending" || messageResponse?.status === "failed") {
            console.warn(`Message (${messageId}) 狀態為 pending，嘗試 POST 開啟任務。`);
            const postMessage = () => request.post(
              `/private/module/rpa/agoda/message/${roomId}`,
              {},
              {
                params: {
                  message_id: messageId,
                  clean_run: false,
                },
              }
            );

            try {
              await postMessage();
            } catch (postError) {
              console.error(`POST message (${messageId}) 失敗：`, postError);
            }

            // get message again
            try {
              await delay(2000);  
              messageResponse = await retryRequest(getMessage);
            } catch (finalError) {
              console.error(`最終 GET message (${messageId}) 失敗：`, finalError);
              continue; // continue to next messageId
            }
          }
        } catch (error) {
          console.warn(`GET message (${messageId}) 失敗，嘗試 POST 開啟任務。`);
          // post message
          const postMessage = () => request.post(
            `/private/module/rpa/airbnb/message/${roomId}`,
            {},
            {
              params: {
                message_id: messageId,
                clean_run: false,
              },
            }
          );

          try {
            await postMessage();
          } catch (postError) {
            console.error(`POST message (${messageId}) 失敗：`, postError);
          }

          // get message again
          try {
            await delay(2000);  
            messageResponse = await retryRequest(getMessage);
          } catch (finalError) {
            console.error(`最終 GET message (${messageId}) 失敗：`, finalError);
            continue; // continue to next messageId
          }
        }

        const messageLastUpdate = messageResponse?.last_update_timestamp;
        console.warn('距離上次更新時間', ((Date.now() / 1000) - messageLastUpdate), '秒');
        
        if (messageLastUpdate && ((Date.now() / 1000) - messageLastUpdate) > 5 * 60) {
          console.warn(`Message (${messageId}) 超過 5 分鐘未更新，嘗試 POST 開啟任務。`);
          
          // post message
          const postMessage = () => request.post(
            `/private/module/rpa/airbnb/message/${roomId}`,
            {},
            {
              params: {
                message_id: messageId,
              },
            }
          );

          try {
            await postMessage();
            // get message again
            messageResponse = await retryRequest(getMessage);
          } catch (error) {
            console.error(`處理 message (${messageId}) 失敗：`, error);
             // continue to next messageId
          }

          // get message again
          try {
            await delay(2000);  
            messageResponse = await retryRequest(getMessage);
          } catch (finalError) {
            console.error(`最終 GET message (${messageId}) 失敗：`, finalError);
            continue; // continue to next messageId
          }
        }

        if (messageResponse.result?.length) {
          messages.push(messageResponse);
          setAirbnbMessage(prevClients => {
            const prevAirbnbClients = Array.isArray(prevClients) ? prevClients : [];
          
            if (messageResponse.length === 0) return prevClients;
          
            // Assuming processAirbnbData returns an object
            const res = processAirbnbData(messageResponse, messageId);
          
            // Ensure res is an object and add it to the array
            const updatedClients = [...prevAirbnbClients, { ...res }];
          
            localStorage.setItem("clientsAirbnb", JSON.stringify(updatedClients));
          
            return updatedClients;
          });
          setLoading(false);
        }

        const processedMessages = processOneAirbnbMessage(
          { result: messages },
          messageId,
          setMessageId,
          roomId
        );

        setClientsAirbnb(prevClients => {
          const prevAirbnbClients = Array.isArray(prevClients) ? prevClients : [];

          const newMessages = processedMessages.filter(
            newMsg => !prevAirbnbClients.some(existingMsg => existingMsg.messageId === newMsg.messageId)
          );

          if (newMessages.length === 0) return prevClients;

          const updatedClients = [...prevAirbnbClients, ...newMessages];

          localStorage.setItem("clientsAirbnb", JSON.stringify(updatedClients));

          return updatedClients;
        });

        messages = [];
      } catch (error) {
        console.error(`處理 message (${messageId}) 時發生錯誤：`, error);
        continue; // continue to next messageId
      }
    }

    // Queue next request
    if (requestQueue.length > 0) {
      const nextRequest = requestQueue.shift();
      setRequestQueue([...requestQueue]);
      fetchAirbnbClients({
        room,
        token: nextRequest.token,
        setClientsAirbnb,
        setLoading,
        setFetching,
        requestQueue,
        setRequestQueue,
        setMessageId,
        roomId: nextRequest.roomId,
      });
    }
  } catch (error) {
    console.error("獲取 Airbnb 客戶端時發生錯誤：", error);
    try {
      // post message_ids
      const postMessageIds = () => request.post(
        `/private/module/rpa/airbnb/message_ids/${roomId}`,
        {},
      );

      await postMessageIds();
    } catch (postError) {
      console.error("POST 開啟任務時發生錯誤：", postError);
    } finally {
      setFetching(false);
      setLoading(false);
    }
  } finally {
    setLoading(false);
    setFetching(false);
  }
};

const processAirbnbData = (rawData, messageId) => {
  if (!rawData || !rawData.result || rawData.result.length === 0) {
    return [];
  }
  const result = rawData.result;
  const processedDataArray = [];
  result.forEach((messageItem) => {
    const isReservationMessage =
      messageItem.sender && messageItem.sender.includes("預訂人");
    if (messageItem.content && messageItem.content.trim() !== "") {
      const processedData = {
        id: messageId,
        channel_type: "airbnb",
        channel_id: "some-channel-id",
        client_id: "some-client-id",
        created_at: `${messageItem.time}`,
        message: isReservationMessage ? messageItem.content : "",
        user_id: "some-user-id",
        user_nickname: messageItem.sender || "Unknown",
        user_image_url: messageItem.sender_photo_url || null,
        blobs: [],

        response: {
          id: "response-id",
          channel_type: "airbnb",
          channel_id: "some-channel-id",
          created_at: `${messageItem.time}`,
          succeed: !isReservationMessage, 
          message: !isReservationMessage ? messageItem.content : "", 
          blobs: [],
          tokens: 0,
          costs: 0,
        },
      };

      if (
        (processedData.message && processedData.message.trim() !== "") ||
        (processedData.response.message &&
          processedData.response.message.trim() !== "")
      ) {
        processedDataArray.push(processedData);
      }
    }
  });

  return processedDataArray;
};

// POST request to fetch order info
const postOrder = (roomId, messageId) => request.post(
  `/private/module/rpa/airbnb/order_info/${roomId}`,
  {},
  {
    params: { message_id: messageId },
  }
);

// GET request to fetch order info
const getOrder = (roomId, messageId) => request.get(`/private/module/rpa/airbnb/order_info/${roomId}`, {
  params: { message_id: messageId },
});

const fetchAirbnbOrder = async (roomId, messageId, setOrderAirbnbMessage) => {
  try {
    let getResponse = await getOrder(roomId, messageId);
    if (getResponse.status === "failed") {
      console.log("GET 失敗，嘗試 POST 任務開啟");
      try {
        await delay(2000);  
        await postOrder(roomId, messageId);
      } catch (error) {
        await delay(5000);  
        getResponse = await getOrder(roomId, messageId);
      }
    }
    if (getResponse.status === "pending") {
      console.log("GET 仍處於 pending，嘗試 POST 任務開啟");
      try {
        await delay(2000);  
        await postOrder(roomId, messageId);
      } catch (error) {
        await delay(5000);  
        getResponse = await getOrder(roomId, messageId);
      }
    }

    if (getResponse) {
      const updatedResponse = { ...getResponse, messageId }; 
      setOrderAirbnbMessage(prevOrders => {
        const prevOrderList = Array.isArray(prevOrders) ? prevOrders : [];
        const updatedOrders = [...prevOrderList, updatedResponse];
        localStorage.setItem("airbnbOrders", JSON.stringify(updatedOrders));
        return updatedOrders;
      });
    }

  } catch (error) {
    console.error("獲取 order 資訊時發生錯誤：", error);
    try {
      await postOrder(roomId, messageId);
      await delay(2000); 
      let getResponse = await getOrder(roomId, messageId);
      if (getResponse.status === "pending") {
        console.log("POST 成功，但 GET 仍 pending，嘗試再次 GET");
        await delay(2000);  
        getResponse = await getOrder(roomId, messageId);
      }
      
      if (getResponse) {
        const updatedResponse = { ...getResponse, messageId };

        setOrderAirbnbMessage(prevOrders => {
          const prevOrderList = Array.isArray(prevOrders) ? prevOrders : [];
          const updatedOrders = [...prevOrderList, updatedResponse];
          localStorage.setItem("airbnbOrders", JSON.stringify(updatedOrders));
          return updatedOrders;
        });
      }
    } catch (postError) {
      console.error("POST 請求失敗：", postError);
    }
  }
};

