import React from 'react';
import { Tabs, Tooltip } from 'antd';
import BoundListItem from './BoundListItem';
import { LoadingOutlined, SwapOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const BoundList = ({ clients, activeId, setActiveId, loading, tabs, activeTab, onChangeTab, switchTo, mqttConnection, newMessageClientList, refresh }) => {
  
  return (
    <div className="pr-3 flex flex-col min-w-72 max-w-72 bg-bgLight px-2 pt-6">
      <div className="flex flex-row items-center justify-between text-2xl font-bold mb-2 text-textDark ml-1">
        <div>
          第三方紀錄
          <Tooltip title="切換至語音模組">
            <SwapOutlined
              className="hover:text-primary ml-2"
              style={{ width: "12px", height: "12px" }}
              onClick={() => switchTo('asr')}
            />
          </Tooltip>
        </div>
        {/* <ReloadOutlined
          className="hover:text-primary ml-2"
          style={{ width: "12px", height: "12px" }}
          onClick={fetchClients}
        /> */}
        {
          mqttConnection ?
            <Tooltip title="連線穩定">
              <div className="flex flex-row gap-1 ml-2 bg-green-600 w-2 h-2 rounded-full" />
            </Tooltip>
            :
            <Tooltip title="重新連線中...點擊以重整">
              <div className="flex flex-row gap-1 ml-2 bg-yellow-600 w-2 h-2 rounded-full animate-ping" onClick={refresh} />
            </Tooltip>
        }
      </div>

      <div className='flex justify-between'>
        <Tabs defaultActiveKey={0} items={tabs} onChange={onChangeTab} className="px-2 w-full" />
      </div>
      {
        loading ?
          <div className="flex justify-center items-center w-full h-screen">
            <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
          </div>
          :
          (clients.length === 0 ?
            <div className="w-full flex flex-col gap-4 items-center justify-center pt-12">
              這裡沒有任何第三方紀錄。
            </div>
            :
            <div className="flex flex-col overflow-y-scroll pb-12 overflow-x-hidden">
              {clients
                .sort((a, b) => new Date(b.last_two_messages[0].created_at) - new Date(a.last_two_messages[0].created_at))
                .map((client) => (
                  <BoundListItem
                    key={client.userId}
                    client={client}
                    activeId={activeId}
                    setActiveId={setActiveId}
                    newMessage={newMessageClientList.includes(client.userId)}
                  />
                ))
              }
            </div>
          )
      }
    </div>
  )
}

export default BoundList;
