import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { request } from '@/utils';
const initialState = {
    template: [],
};

// fetch template
export const fetchTemplate = createAsyncThunk(
    'memory/fetchTemplate',
    async ({ room_id }) => {
        const res = await request.get(
            `/private/chatrooms/vector_store/template/${room_id}/all`);
        return res;
    }
);
// add template
export const addTemplate = createAsyncThunk(
    'memory/addTemplate',
    async ({ room_id, template} ) => {
        const res = await request.post(
            `/private/chatrooms/vector_store/template/${room_id}`, template);
        return res;
    }
);
// update template
export const updateTemplate = createAsyncThunk(
    'memory/updateTemplate',
    async ({ room_id, template, template_id }) => {
        const res = await request.put(
            `/private/chatrooms/vector_store/template/${room_id}/sample/${template_id}`,
            template
        );
        return res;
    }
);

// memory service
const memoryService = createSlice({
    name: 'memory',
    initialState,
    reducers: {
        // Set the template in memory
        setTemplate(state, action) {
            const newTemplate = action.payload;
            if (Array.isArray(newTemplate)) {
                state.template = newTemplate;
            } else if (typeof newTemplate === 'object' && newTemplate !== null) {
                const index = state.template.findIndex(
                    (item) => item.id === newTemplate.id
                );
                if (index !== -1) {
                    state.template[index] = newTemplate;
                } else {
                    state.template.push(newTemplate);
                }
            } else {
                console.error(
                    'Invalid payload format: expected an array or an object.'
                );
            }
        },
        // clear the template in memory
        clearTemplate(state) {
            state.template = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTemplate.fulfilled, (state, action) => {
                // update template
                state.template = action.payload;
            })
            .addCase(addTemplate.fulfilled, (state, action) => {
                // when success then add template
                state.template.push(action.payload);
            })
            .addCase(updateTemplate.fulfilled, (state, action) => {
                // update template
                const index = state.template.findIndex(
                    (item) => item.id === action.payload.id
                );
                if (index !== -1) {
                    state.template[index] = action.payload;
                }
            });
    }
});


export const { setTemplate, clearTemplate } = memoryService.actions;
export default memoryService.reducer;