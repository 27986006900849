import React from 'react';
import { Spin, Button } from 'antd';
import { LoadingOutlined, ReloadOutlined, CloseOutlined } from '@ant-design/icons';

// Advanced component
export const Advanced = ({
  handleButtonClick,
  selectedButton,
  fetching,
  template,
  setSelectedTemplate,
  setSelectedTemplateIndex,
  setShowTemplateEditor,
  setShowCurrentMemory,
  memory,
  handleOpenModal,
  deleteMemory,
  fetchTemplate,
  allRecords,
  setFetching,
  room,
  token,
  dispatch,
}) => {
  const handleFetchTemplate = async () => {
    setFetching(true);
    try {
      await dispatch(fetchTemplate(room.id, token));
    } finally {
      setFetching(false);
    }
  };
  return (
    <>
      <div className="flex flex-row w-full h-full p-1">
        <div className="flex flex-col w-1/2 h-full items-start justify-start border-r border-r-borderLight p-3">
          {/* nav btn */}
          <div className="flex justify-between bg-gray-100 w-[276px] rounded mb-4">
            <button
              onClick={() => handleButtonClick("general")}
              className={`px-2 py-1 flex-1 rounded ${selectedButton === "general" ? "bg-[#ADDEAC]" : "bg-gray-100"
              }`}
            >
              一般記憶
            </button>
            <button
              onClick={() => handleButtonClick("advanced")}
              className={`px-2 py-1 flex-1 rounded ${selectedButton === "advanced" ? "bg-[#ADDEAC]" : "bg-gray-100"
              }`}
            >
              進階記憶
            </button>
            <button
              onClick={() => handleButtonClick("qa")}
              className={`px-2 py-1 flex-1 rounded ${selectedButton === "qa" ? "bg-[#ADDEAC]" : "bg-gray-100"
              }`}
            >
              問答記憶
            </button>
          </div>
          <div className="flex flex-row bg-bgDark h-8 items-center w-full gap-2 px-2">
            <div className="flex w-[600px] text-sm">模板名稱</div>
            <div className="flex min-w-4 bg-bgDark h-full ml-2" />
          </div>
          {fetching ? (
            <div className="flex justify-center items-center w-full h-72">
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
              />
            </div>
          ) : (
            <>
              {template.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-row justify-between border-b border-b-borderLight h-11 items-center w-full gap-2 px-2"
                >
                  <div className="flex cursor-pointer hover:underline truncate">
                    {item.name}
                  </div>
                  <div>
                    <Button
                      onClick={() => {
                        setSelectedTemplate(item);
                        setSelectedTemplateIndex(index);
                        setShowTemplateEditor(true);
                      }}
                      className="bg-transparent border-none hover:bg-transparent active:bg-transparent"
                    >
                      <img src="/images/edit-icon.png" alt="edit-icon" />
                    </Button>
                  </div>
                </div>
              ))}
              <div className="flex justify-end w-full mt-3">
                <Button
                  className="text-black-900 text-[16px] leading-[1.3] w-[58px] bg-[#ADDEAC] flex items-center justify-center"
                  style={{ transition: 'background-color 0.3s, color 0.3s' }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = 'rgba(173, 222, 172, 0.8)';
                    e.currentTarget.style.color = 'inherit';
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = '#ADDEAC';
                    e.currentTarget.style.color = 'inherit';
                  }}
                  onClick={() => {
                    setSelectedTemplate(null);
                    setSelectedTemplateIndex(null);
                    setShowTemplateEditor(true);
                  }}
                >
                  新增
                </Button>
              </div>
            </>
          )}
        </div>
        <div className="flex flex-col w-1/2 min-h-full h-fit items-center justify-start border-l border-l-borderLight p-4 gap-4 overflow-visible pb-8">
  <h1 className="text-lg font-bold text-textDark self-start">
    目前記憶
    <ReloadOutlined
      className="hover:text-primary ml-2"
      style={{ width: "12px", height: "12px" }}
      onClick={handleFetchTemplate}
    />
  </h1>
  <div className="flex flex-col w-full">
    {/* 表頭 */}
    <div className="flex flex-row justify-start bg-bgDark h-8 items-center w-full gap-2 px-2">
      {/* 設定一致的 flex-basis 或寬度 */}
      <div className="flex justify-start text-sm w-1/4">記憶名稱</div>
      <div className="flex justify-start text-sm w-1/4">模板名稱</div>
      <div className="flex justify-start text-sm w-1/4">建立時間</div>
      <div className="flex w-1/4 bg-bgDark h-full ml-2" />
    </div>

    {/* 內容區塊 */}
    {fetching ? (
      <div className="flex justify-center items-center w-full h-72">
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
        />
      </div>
    ) : (
      allRecords.map((item, index) => (
        <div
          key={index}
          className="flex flex-row justify-between border-b border-b-borderLight h-11 items-center w-full gap-2 px-2"
        >
          <div
            className="flex justify-start items-center cursor-pointer hover:underline truncate w-1/4"
            onClick={() =>
              handleOpenModal(
                item.blob.content_type,
                item.blob.url,
                item.id
              )
            }
          >
            {item.template_id
              ? template.find((t) => t.id === item.template_id)?.structure.value || "未指定"
              : "未指定"}
          </div>

          <div className="flex justify-start items-center w-1/4">
            {item.template_id
              ? template.find((t) => t.id === item.template_id)?.name || "未指定"
              : "未指定"}
          </div>

          <div className="flex justify-start items-center w-1/4">
            {item.blob.created_at.split(" ")[0]}
          </div>

          <div className="flex justify-end w-1/4">
          <Button
            onClick={() => {
              setSelectedTemplate(item);
              setSelectedTemplateIndex(index);
              setShowTemplateEditor(true);
            }}
            className="bg-transparent border-none hover:bg-transparent active:bg-transparent "
          >
            <img src="/images/edit-icon.png" alt="edit-icon" />
          </Button>
          </div>
        </div>
      ))
    )}
    <div className="flex justify-end w-full mt-3">
      <Button
        className="text-black-900 text-[16px] leading-[1.3] w-[58px] bg-[#ADDEAC] flex items-center justify-center"
                    style={{ transition: 'background-color 0.3s, color 0.3s' }}
        onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor = 'rgba(173, 222, 172, 0.8)';
                      e.currentTarget.style.color = 'inherit';
        }}
        onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = '#ADDEAC';
                      e.currentTarget.style.color = 'inherit';
                    }} onClick={() => {
          setShowCurrentMemory(true);
        }}
      >
        新增
      </Button>
    </div>
  </div>
</div>
      </div>
    </>
  );
};