import React, { useState, useEffect } from 'react';
import {request} from '@/utils';
import { useBound } from '@/contexts/BoundContext';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const AirbnbSetting = (client) => {
  const { roomId, messageId, orderAirbnbMessage } = useBound();
  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState(null);
  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
  
  useEffect(() => {
    if (client.client.channel === "airbnb") {
      // filter out data with matching messageId and client.messageId
      const filteredOrders = orderAirbnbMessage.filter(
        (item) => item.messageId === client.client.messageId
      );
  
      // flatten each order after filtering
      const ordersObject = filteredOrders.reduce((acc, order) => {
        return { ...acc, ...order }; // flatten each order's properties
      }, {});
  
      // store the flattened object in orderData state
      setOrderData(ordersObject);
    }
  }, [client, orderAirbnbMessage, setOrderData]);
  
  
  

  // POST request to fetch order info
  const postOrder = () => request.post(
    `/private/module/rpa/airbnb/order_info/${roomId}`,
    {},
    {
      params: { message_id: messageId },
    }
  );

  // GET request to fetch order info
  const getOrder = () => request.get(`/private/module/rpa/airbnb/order_info/${roomId}`, {
    params: { message_id: messageId },
  });

  const fetchOrderInfo = async () => {
    setLoading(true);  // Start loading
    try {
      let getResponse = await getOrder();
      
      // Handle failed response
      if (getResponse.status === "failed") {
        console.log("Initial GET failed, attempting to POST");
        try {
          await delay(2000);  
          await postOrder();
        } catch (error) {
          await delay(5000);  
          getResponse = await getOrder();
        }
      }
      if (getResponse.status === "pending") {
        console.log("Initial GET pending, attempting to POST");
        try {
          await delay(2000);  
          await postOrder();
        } catch (error) {
          await delay(5000);  
          getResponse = await getOrder();
        }
      }
      setOrderData(getResponse);  // Update order data
    } catch (error) {
      console.error("Error fetching order info:", error);  // Log errors for visibility
      try {
        console.log("Attempting POST as recovery");
        await postOrder();
        await delay(2000);  // Adding delay before retrying
        let getResponse = await getOrder();
        if (getResponse.status === "pending") {
          console.log("Initial GET pending, attempting to POST");
          try {
            await delay(2000);  
            await postOrder();
          } catch (error) {
            await delay(2000);  
            getResponse = await getOrder();
          }
          await delay(2000);  // Adding delay before retrying
          getResponse = await getOrder();
        }
        setOrderData(getResponse);  // Set response if POST and retry were successful
      } catch (postError) {
        console.error("POST request failed as well:", postError);
      }
    } finally {
      setLoading(false);  // Always stop loading
    }
  };

  // Fetch order info on component mount
  useEffect(() => {
    setOrderData(null);
  }, [messageId, roomId]);

  // Get order detail by keyword
  const getOrderDetailByKeyword = (details, keyword) => {
    const index = details ? details.findIndex(detail => detail.includes(keyword)) : -1;
    return index !== -1 && details[index] ? details[index] : '';
  };

  return (
    <>
      <div className='p-3'>
        <div className="flex justify-between font-inter text-[17px] font-semibold leading-[22.1px] text-left text-[#3E3D3D] w-full my-3">
          <button onClick={() => fetchOrderInfo()}>預定詳情</button>
        </div>
        <div className="flex flex-col border border-borderLight rounded bg-bgMedium w-full rounded-[11px]">
        {
        loading ?
          <div className="flex justify-center items-center h-[600px]">
            <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
          </div>
          :
          <>
            <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
            <div className="font-[500]">客戶名稱</div>
            <div className="text-[14px] font-[400]">{orderData?.result?.order_summary?.[1] || <span className='text-gray-400'>查無相關資料</span>}</div>
          </div>
          <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
            <div className="font-[500]">房間名稱</div>
            <div className="text-[14px] font-[400]">{orderData?.result?.order_summary?.[2] || <span className='text-gray-400'>查無相關資料</span>}</div>
          </div>
          <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
            <div className="font-[500]">價格</div>
            <div className="text-[14px] font-[400]">{orderData?.result?.order_summary?.[4] || <span className='text-gray-400'>查無相關資料</span>}</div>
          </div>
          <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
            <div className="font-[500]">入房</div>
            <div className="text-[14px] font-[400]">{getOrderDetailByKeyword(orderData?.result?.order_details, '入住')|| <span className='text-gray-400'>查無相關資料</span>}</div>
          </div>
          <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
            <div className="font-[500]">退房</div>
            <div className="text-[14px] font-[400]">{getOrderDetailByKeyword(orderData?.result?.order_details, '退房')|| <span className='text-gray-400'>查無相關資料</span>}</div>
          </div>
          <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
            <div className="font-[500]">預定日期</div>
            <div className="text-[14px] font-[400]">{getOrderDetailByKeyword(orderData?.result?.order_details, '預定') || <span className='text-gray-400'>查無相關資料</span>}</div>
          </div>
          <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
            <div className="font-[500]">驗證碼</div>
            <div className="text-[14px] font-[400]">{getOrderDetailByKeyword(orderData?.result?.order_details, '驗證') || <span className='text-gray-400'>查無相關資料</span>}</div>
          </div>
          </>
        }
        </div>
      </div>
    </>
  );
}

export default AirbnbSetting;
