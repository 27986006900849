import React, { useState } from "react";
import { Input, Button, Row, Col, Space, Card, Select } from "antd";
import {
  PlusCircleOutlined,
  CloseCircleOutlined,
  DoubleRightOutlined,
} from "@ant-design/icons";
const { Option } = Select;

// LevelSection component
const LevelSection = ({ level, items, onAdd }) => {
  return (
    <Card
      bordered={false}
      style={{ marginBottom: 20, height: '100vh', backgroundColor: 'white', border: '1px solid #E0E0E0', borderRadius: '0',margin: '0' }} 
    >
      <div className="text-[14px] mb-5">Level {level}</div>
      {items.map((item, index) => (
        <Row
          key={index}
          gutter={16}
          align="middle"
          style={{ marginBottom: 10 }}
        >
          <Col span={16}>
            <Input placeholder="" value={item} />
          </Col>
          <Col span={8}>
            <Space>
              <Button icon={<CloseCircleOutlined />} />
              <Button icon={<DoubleRightOutlined />} />
            </Space>
          </Col>
        </Row>
      ))}
      <Button
        style={{ color: "#67BE5F", width: '188px' }}
        type="dashed"
        onClick={onAdd}
        block
        icon={<PlusCircleOutlined />}
      >
        新增Level {level}標籤
      </Button>
    </Card>
  );
};

// CurrentMemory component
const CurrentMemory = ({
  setShowCurrentMemory,
  handleButtonClick,
  selectedButton,
}) => {
  const [templateName, setTemplateName] = useState("");
  const [level1Items, setLevel1Items] = useState([""]);
  const [level2Items, setLevel2Items] = useState([""]);
  const [level3Items, setLevel3Items] = useState([""]);

  // Handle level change
  const handleLevelChange = (level, index, value) => {
    if (level === 1) {
      setLevel1Items(
        level1Items.map((item, i) => (i === index ? value : item))
      );
    } else if (level === 2) {
      setLevel2Items(
        level2Items.map((item, i) => (i === index ? value : item))
      );
    } else if (level === 3) {
      setLevel3Items(
        level3Items.map((item, i) => (i === index ? value : item))
      );
    }
  };

  return (
    <div className="h-[100vh]">
      {/* nav btn */}
      <div className="flex justify-between bg-gray-100 w-[276px] rounded m-4">
        <button
          onClick={() => handleButtonClick("general")}
          className={`px-2 py-1 flex-1 rounded ${
            selectedButton === "general" ? "bg-[#ADDEAC]" : "bg-gray-100"
          }`}
        >
          一般記憶
        </button>
        <button
          onClick={() => handleButtonClick("advanced")}
          className={`px-2 py-1 flex-1 rounded ${
            selectedButton === "advanced" ? "bg-[#ADDEAC]" : "bg-gray-100"
          }`}
        >
          進階記憶
        </button>
        <button
          onClick={() => handleButtonClick("qa")}
          className={`px-2 py-1 flex-1 rounded ${
            selectedButton === "qa" ? "bg-[#ADDEAC]" : "bg-gray-100"
          }`}
        >
          問答記憶
        </button>
      </div>
      <div>
        <div className="flex justify-between m-4">
          <div className="flex flex-row gap-4">
            <div>
              <span className="text-[14px]">模板名稱</span>
              <Row justify="center" style={{ marginBottom: 20, marginTop: 5 }}>
                <Select
                  placeholder="請選擇模板名稱"
                  onChange={(value) => setTemplateName(value)}
                  style={{ width: "200px" }}
                >
                  <Option value="template1">模板1</Option>
                  <Option value="template2">模板2</Option>
                  <Option value="template3">模板3</Option>
                  {/* Add more options as needed */}
                </Select>
              </Row>
            </div>
            <div>
              <span className="text-[14px]">記憶名稱</span>
              <Row justify="center" style={{ marginBottom: 20, marginTop: 5 }}>
                <Input
                  placeholder="輸入名稱"
                  value={templateName}
                  onChange={(e) => setTemplateName(e.target.value)}
                />
              </Row>
            </div>
          </div>
          <Row justify="center" className="gap-5">
            <Button onClick={() => setShowCurrentMemory(false)}>取消</Button>
            <Button type="primary">儲存</Button>
          </Row>
        </div>
      </div>
      <Row gutter={0} style={{ height: '100%' }}>
        <Col span={8} style={{ height: '100%' }}>
          <LevelSection
            level={1}
            items={level1Items}
            onAdd={() => setLevel1Items([...level1Items, ""])}
            onChange={handleLevelChange}
          />
        </Col>
        <Col span={8} style={{ height: '100%' }}>
          <LevelSection
            level={2}
            items={level2Items}
            onAdd={() => setLevel2Items([...level2Items, ""])}
            onChange={handleLevelChange}
          />
        </Col>
        <Col span={8} style={{ height: '100%' }}>
          <LevelSection
            level={3}
            items={level3Items}
            onAdd={() => setLevel3Items([...level3Items, ""])}
            onChange={handleLevelChange}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CurrentMemory;
