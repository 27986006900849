import React, { useState, useEffect } from "react";
import mqtt from "mqtt";
import { useAuth } from "@/contexts/AuthProvider";
import { useBound } from "@/contexts/BoundContext";
import BoundList from "./BoundList";
import BoundChatRoom from "./BoundChatRoom";
import BoundSetting from "./BoundSetting";
import { fetchAirbnbClients, refreshAirbnb } from "./platforms/airbnb";
import { fetchLineClients } from "./platforms/line";
import { fetchAgodaClients } from "./platforms/agoda";
import { SERVER_URL, MQTT_URL, MQTT_PORT, tabList } from "@/constants/constants";

const BoundInterface = ({ room, switchTo }) => {
  const { userId, token } = useAuth();
  const {
    setAirbnbMessage,
    setAgodaMessage,
    setOrderAirbnbMessage, // set airbnb order
    setOrderAgodaMessage,// set agoda order
    orderAgodaMessage, // agoda order
  } = useBound();

  const [clients, setClients] = useState([]);
  const [clientsAirbnb, setClientsAirbnb] = useState([]);
  const [clientsAgoda, setClientsAgoda] = useState([]);

  const [channelType, setChannelType] = useState('line');
  const [chatType] = useState('ai');
  const [activeId, setActiveId] = useState('');
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [mqttConnection, setMqttConnection] = useState(false);
  const [activeClient, setActiveClient] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [newMessageClientList, setNewMessageClientList] = useState([]);
  const [requestQueue, setRequestQueue] = useState([]);
  const [messageId, setMessageId] = useState(null);
  const roomId = room.id;
  
  useEffect(() => {
      if (activeTab === 1) {
        fetchLineClients(
          SERVER_URL,
          room,
          token,
          setClients,
          setLoading,
          channelType,
          chatType
        );
      }
  }, [activeTab]);

  useEffect(() => {
    setMqttConnection(false);
    fetchAirbnbClients(
      SERVER_URL,
      room,
      token,
      setClientsAirbnb,
      setLoading,
      setFetching,
      requestQueue,
      setRequestQueue,
      setMessageId,
      roomId,
      setAirbnbMessage,
      setOrderAirbnbMessage
    );

    fetchAgodaClients(
      SERVER_URL,
      room,
      token,
      setClientsAgoda,
      setLoading,
      setFetching,
      requestQueue,
      setRequestQueue,
      setMessageId,
      roomId,
      setAgodaMessage,
      setOrderAgodaMessage,
      orderAgodaMessage
    );

    setActiveId("");
    setActiveClient(null);
    setNewMessageClientList([]);
  }, [room]);

  // Set active client when activeId changes
  useEffect(() => {
    const sourceClients =
      activeTab === 0
        ? clientsAirbnb
        : activeTab === 1
        ? clients
        : clientsAgoda;
    setActiveClient(sourceClients.find((client) => client.userId === activeId));
    setNewMessageClientList((prevList) =>
      prevList.filter((clientId) => clientId !== activeId)
    );
  }, [activeId, activeTab, clients, clientsAirbnb, clientsAgoda]);

  // MQTT connect
  useEffect(() => {
    // Establish connection with the MQTT broker
    const mqttClient = mqtt.connect(MQTT_URL, {
      clientId: 'mqttx_' + Math.random().toString(16).substring(2, 10),
      port: MQTT_PORT,
      path: '/',
      connectTimeout: 10000,
      keepAlive: 30000,
      autoReconnect: true,
      reconnectPeriod: 1000,
      cleanStart: false,
    });

    mqttClient.on('error', (error) => {
      // console.error('Connection error:', error);
      setMqttConnection(false);
    });

    mqttClient.on('connect', () => {
      mqttClient.subscribe(`chatroom/${room.id}/line/#`, (error) => {
        if (error) {
          // console.error('Subscription error:', error);
          setMqttConnection(false);
        } else {
          // console.log('Subscription successful');
          setMqttConnection(true);
        }
      });
    });

    mqttClient.on('message', (topic, payload) => {
      const receivedClientId = topic.split('/')[3].toString();
      // console.log('INTERFACE | Message received:', receivedClientId, "content", payload.toString());
      // console.log('INTERFACE | Active clients:', clients);

      // filter new client
      // const newClient = clients.find(client => client.userId === receivedClientId);
      // console.log('the new client', newClient)

      // unfocused client got message
      if (receivedClientId !== activeId) {
        setNewMessageClientList((prevList) => [...prevList, receivedClientId]);
      }
      // new client or message
      fetchLineClients(
        SERVER_URL,
        room,
        token,
        setClients,
        setLoading,
        channelType,
        chatType
      );
      fetchAirbnbClients(
        SERVER_URL,
        room,
        token,
        setClientsAirbnb,
        setLoading,
        setFetching,
        requestQueue,
        setRequestQueue,
        setMessageId,
        roomId
      );
      fetchAgodaClients(
        SERVER_URL,
        room,
        token,
        setClientsAgoda,
        setLoading,
        setFetching,
        requestQueue,
        setRequestQueue,
        setMessageId,
        roomId
      );
    });
    // Clean up the connection when the component unmounts
    return () => mqttClient.end();
  }, [clients, activeId]);

  // Change tab
  const onChangeTab = (key) => {
    setActiveTab(key);
    setChannelType(key === 0 ? 'airbnb' : key === 1 ? 'line' : 'agoda');
  };

  return (
    <div className="w-full flex flex-row">
      <BoundList
        clients={
          activeTab === 0
            ? clientsAirbnb
            : activeTab === 1
            ? clients
            : clientsAgoda
        }
        // clients={clientsAirbnb}
        activeId={activeId}
        setActiveId={setActiveId}
        loading={loading}
        tabs={tabList}
        activeTab={activeTab}
        onChangeTab={onChangeTab}
        switchTo={switchTo}
        mqttConnection={mqttConnection}
        newMessageClientList={newMessageClientList}
        refresh={() =>
          refreshAirbnb(fetching, () =>
            fetchAirbnbClients(
              SERVER_URL,
              room,
              token,
              setClientsAirbnb,
              setClientsAgoda,
              setLoading,
              setFetching,
              requestQueue,
              setRequestQueue,
              setMessageId,
              roomId
            )
          )
        }
      />
      {activeClient ? (
        <>
          <BoundChatRoom
            userId={userId}
            token={token}
            room={room}
            client={activeClient}
            tabs={activeTab}
          />
          <BoundSetting
            room={room}
            token={token}
            clientId={activeClient.userId}
            tabs={activeTab}
            client={activeClient}
          />
        </>
      ) : (
        <div className="flex flex-row items-center justify-center w-full h-full text-2xl font-bold text-textLight">
          請選擇第三方紀錄
        </div>
      )}
    </div>
  );
};

export default BoundInterface;
