import { request } from "@/utils";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// retry
const retryRequest = async (requestFn, retries = 5, delayMs = 2000) => {
  for (let attempt = 1; attempt <= retries; attempt++) {
    try {
      return await requestFn();
    } catch (error) {
      console.error(`Attempt ${attempt} failed:`, error);
      if (attempt < retries) {
        await delay(delayMs);
      } else {
        throw error;
      }
    }
  }
};
// process last message
export const processOneAgodaMessage = (rawData, mes, setMessageId, roomId, guestName) => {
  setMessageId(mes);
  
  // check if rawData.result is an array
  if (!rawData.result || !Array.isArray(rawData.result)) {
    console.warn(`rawData.result 不是一個陣列，無法處理 message (${mes})`);
    return [];
  }

  return rawData.result
    .map((item) => {
      // check if item.result is an array
      if (!item.result || !Array.isArray(item.result) || item.result.length === 0) {
        return null;
      }

      // find the sender with reservation
      const senderWithReservation = item.result.find(
        (message) =>
          message.sender_role && message.sender_role.includes("guest")
      );

      const displayName = senderWithReservation
        ? guestName
        : guestName;
      const pictureUrl = senderWithReservation
        ? senderWithReservation.sender_photo_url
        : null;

      let lastMessage = null;

      // find the last message with body
      for (let i = item.result.length - 1; i >= 0; i--) {
        if (item.result[i].body) {
          lastMessage = item.result[i];
          break;
        }
      }

      // if no message found
      if (!lastMessage) {
        lastMessage = {
          body: "No messages",
          send_date: "",
          sender_role: "",
        };
      }

      return {
        id: item.last_update_timestamp,
        userId: item.last_update_timestamp ? item.last_update_timestamp : "· 預訂人",
        messageId: mes,
        roomId: roomId,
        displayName: displayName,
        pictureUrl: pictureUrl,
        channel: "agoda",
        last_two_messages: [
          {
            created_at: `${lastMessage.send_date}`,
            message: lastMessage.body,
          },
        ],
        unreadCount: 1,
      };
    })
    .filter((item) => item !== null); 
};

// refresh agoda clients
export const refreshAgoda = (fetching, fetchAgodaClients) => {
  if (!fetching) {
    fetchAgodaClients(true);
  }
};

const getprofile = async (roomId, setLoading) => {
  try {
    await request.get(`/private/module/rpa/profile/${roomId}`);
    console.warn("Profile fetched successfully");
    return true;
  } catch (error) {
    console.warn("Error fetching profile");
    setLoading(false);
    return false;
  }
};

// fetch agoda clients
export const fetchAgodaClients = async (
  serverUrl,
  room,
  token,
  setClientsAgoda,
  setLoading,
  setFetching,
  requestQueue,
  setRequestQueue,
  setMessageId,
  roomId,
  setAgodaMessage,
  setOrderAgodaMessage,
  orderAgodaMessage
) => {
  setLoading(true);
  setFetching(true);
  const profileSuccess = await getprofile(room.id, setLoading);
  if (!profileSuccess) {
    return;
  }

  if (requestQueue.length > 0) {
    setRequestQueue((prevQueue) => [...prevQueue, { roomId, token }]);
    setLoading(false);
    setFetching(false);
    return;
  }

  try {
    // get message ids
    const getMessageIds = () =>
      request.get(`/private/module/rpa/agoda/message_ids/${roomId}`);

    let idsResponse;
    try {
      idsResponse = await retryRequest(getMessageIds);
    } catch (error) {
      console.warn("GET message_ids 失敗，嘗試 POST 開啟任務。");
      // post message ids
      const postMessageIds = () =>
        request.post(`/private/module/rpa/agoda/message_ids/${roomId}`, {});

      try {
        await postMessageIds();
      } catch (postError) {
        console.error("POST message_ids 失敗：", postError);
      }

      // get message ids again
      try {
        idsResponse = await retryRequest(getMessageIds);
      } catch (finalError) {
        console.error("最終 GET message_ids 失敗：", finalError);
        return;
      }
    }

    if (!idsResponse?.result?.length) {
      console.warn("沒有返回 message IDs。");
      return;
    }

    const messageIds = idsResponse.result;
    let messages = [];

    for (const messageId of messageIds) {
      try {
        // get message
        await delay(2000);
        const getMessage = () =>
          request.get(`/private/module/rpa/agoda/message/${roomId}`, {
            params: {
              message_id: messageId,
            },
          });
        // add order info fetching
       const res = await fetchAgodaOrder(roomId, messageId, setOrderAgodaMessage, orderAgodaMessage);
      
       const guestName =  res.result.guest_details[0][1]
      

        let messageResponse;
        try {
          messageResponse = await retryRequest(getMessage);
          if (
            messageResponse.status === "pending" ||
            messageResponse.status === "failed"
          ) {
            console.warn(
              `Message (${messageId}) 狀態為 pending，嘗試 POST 開啟任務。`
            );

            // post message
            const postMessage = () =>
              request.post(
                `/private/module/rpa/agoda/message/${roomId}`,
                {},
                {
                  params: {
                    message_id: messageId,
                    clean_run: false,
                  },
                }
              );

            try {
              await postMessage();
            } catch (postError) {
              console.error(`POST message (${messageId}) 失敗：`, postError);
            }

            // get message again
            try {
              await delay(2000);
              messageResponse = await retryRequest(getMessage);
            } catch (finalError) {
              console.error(
                `最終 GET message (${messageId}) 失敗：`,
                finalError
              );
              continue; // continue to next messageId
            }
          }
        } catch (error) {
          console.warn(`GET message (${messageId}) 失敗，嘗試 POST 開啟任務。`);

          // post message
          const postMessage = () =>
            request.post(
              `/private/module/rpa/agoda/message/${roomId}`,
              {},
              {
                params: {
                  message_id: messageId,
                  clean_run: false,
                },
              }
            );

          try {
            await postMessage();
          } catch (postError) {
            console.error(`POST message (${messageId}) 失敗：`, postError);
          }

          // get message again
          try {
            await delay(2000);
            messageResponse = await retryRequest(getMessage);
          } catch (finalError) {
            console.error(`最終 GET message (${messageId}) 失敗：`, finalError);
            continue; // continue to next messageId
          }
        }

        const messageLastUpdate = messageResponse?.last_update_timestamp;
        console.warn(
          "距離上次更新時間",
          Date.now() / 1000 - messageLastUpdate,
          "秒"
        );
        if (
          messageLastUpdate &&
          Date.now() / 1000 - messageLastUpdate > 5 * 60
        ) {
          console.warn(
            `Message (${messageId}) 超過 5 分鐘未更新，嘗試 POST 開啟任務。`
          );

          // post message
          const postMessage = () =>
            request.post(
              `/private/module/rpa/agoda/message/${roomId}`,
              {},
              {
                params: {
                  message_id: messageId,
                },
              }
            );

          try {
            // post message
            await postMessage();
            // get message again
            messageResponse = await retryRequest(getMessage);
          } catch (error) {
            console.error(`處理 message (${messageId}) 失敗：`, error);
          }

          // get
          try {
            await delay(2000);
            messageResponse = await retryRequest(getMessage);
          } catch (finalError) {
            console.error(`最終 GET message (${messageId}) 失敗：`, finalError);
            continue; // continue to next messageId
          }
        }

        if (messageResponse.result?.length) {
          messages.push(messageResponse);
          setAgodaMessage((prevClients) => {
            const prevAgodaClients = Array.isArray(prevClients)
              ? prevClients
              : [];

            if (messageResponse.length === 0) return prevClients;

            // Assuming processAgodaData returns an object
            const res = processAgodaData(messageResponse, messageId);

            // Ensure res is an object and add it to the array
            const updatedClients = [...prevAgodaClients, { ...res }];

            localStorage.setItem(
              "clientsAgoda",
              JSON.stringify(updatedClients)
            );

            return updatedClients;
          });
          setLoading(false);
        }

        const processedMessages = processOneAgodaMessage(
          { result: messages },
          messageId,
          setMessageId,
          roomId,
          guestName
        );

        setClientsAgoda((prevClients) => {
          const prevAgodaClients = Array.isArray(prevClients)
            ? prevClients
            : [];

          const newMessages = processedMessages.filter(
            (newMsg) =>
              !prevAgodaClients.some(
                (existingMsg) => existingMsg.messageId === newMsg.messageId
              )
          );

          if (newMessages.length === 0) return prevClients;

          const updatedClients = [...prevAgodaClients, ...newMessages];

          localStorage.setItem("clientsAgoda", JSON.stringify(updatedClients));

          return updatedClients;
        });

        messages = [];
      } catch (error) {
        console.error(`處理 message (${messageId}) 時發生錯誤：`, error);
        continue; // continue to next messageId
      }
    }

    // queue next request
    if (requestQueue.length > 0) {
      const nextRequest = requestQueue.shift();
      setRequestQueue([...requestQueue]);
      fetchAgodaClients({
        serverUrl,
        room,
        token: nextRequest.token,
        setClientsAgoda,
        setLoading,
        setFetching,
        requestQueue,
        setRequestQueue,
        setMessageId,
        roomId: nextRequest.roomId,
      });
    }
  } catch (error) {
    console.error("獲取 Agoda 客戶端時發生錯誤：", error);
    try {
      // post message ids
      const postMessageIds = () =>
        request.post(`/private/module/rpa/agoda/message_ids/${roomId}`);
      // post message ids
      await postMessageIds();
    } catch (postError) {
      console.error("POST 開啟任務時發生錯誤：", postError);
    } finally {
      setFetching(false);
      setLoading(false);
    }
  } finally {
    setLoading(false);
    setFetching(false);
  }
};

const processAgodaData = (rawData, messageId) => {
  if (!rawData || !rawData.result) {
    return [];
  }

  let result = rawData.result;

  if (!Array.isArray(result)) {
    result = [result];
  }

  const processedDataArray = [];
  result.forEach((messageItem) => {
    const isReservationMessage =
      messageItem.sender_role && messageItem.sender_role.includes("guest"); 
    if (messageItem.body && messageItem.body.trim() !== "") {
      const processedData = {
        id: messageId,
        channel_type: "agoda",
        channel_id: "some-channel-id",
        client_id: "some-client-id",
        created_at: `${messageItem.send_date}`,
        message: isReservationMessage ? messageItem.body : "",
        user_id: "some-user-id",
        user_nickname: messageItem.sender || "Unknown",
        user_image_url: messageItem.sender_photo_url || null,
        blobs: [],

        response: {
          id: "response-id",
          channel_type: "agoda",
          channel_id: "some-channel-id",
          created_at: `${messageItem.send_date}`,
          succeed: !isReservationMessage,
          message: !isReservationMessage ? messageItem.body : "",
          blobs: [],
          tokens: 0,
          costs: 0,
        },
      };
      if (
        (processedData.message && processedData.message.trim() !== "") ||
        (processedData.response.message &&
          processedData.response.message.trim() !== "")
      ) {
        processedDataArray.push(processedData);
      }
    }
  });

  return processedDataArray;
};

// POST request to fetch order info
const postOrder = (roomId, messageId) =>
  request.post(
    `/private/module/rpa/agoda/order_info/${roomId}`,
    {},
    {
      params: { message_id: messageId },
    }
  );

// GET request to fetch order info
const getOrder = (roomId, messageId) =>
  request.get(`/private/module/rpa/agoda/order_info/${roomId}`, {
    params: { message_id: messageId },
  });

const fetchAgodaOrder = async (roomId, messageId, setOrderAgodaMessage, orderAgodaMessage) => {
  let result
  try {
    let getResponse = await getOrder(roomId, messageId);

    // Handle failed response
    if (getResponse.status === "failed") {
      console.log("Initial GET failed, attempting to POST");
      try {
        await delay(2000);
        await postOrder(roomId, messageId);
      } catch (error) {
        await delay(3000);
        getResponse = await getOrder(roomId, messageId);
      }
    }
    if (getResponse.status === "pending") {
      console.log("Initial GET pending, attempting to POST");
      try {
        await delay(2000);
        await postOrder(roomId, messageId);
      } catch (error) {
        await delay(3000);
        getResponse = await getOrder(roomId, messageId);
      }
    }
    if (getResponse) {
      
      const updatedResponse = { ...getResponse, messageId }; 

      setOrderAgodaMessage(prevOrders => {
        const prevOrderList = Array.isArray(prevOrders) ? prevOrders : [];
        const updatedOrders = [...prevOrderList, updatedResponse];
        localStorage.setItem("agodaOrders", JSON.stringify(updatedOrders));
        return updatedOrders;
      });
      result = getResponse;      
    }
    // Set guest name if available
  } catch (error) {
    console.error("Error fetching order info:", error); // Log errors for visibility
    try {
      console.log("Attempting POST as recovery");
      await postOrder(roomId, messageId);
      await delay(2000); // Adding delay before retrying
      let getResponse = await getOrder();
      if (getResponse.status === "pending") {
        console.log("Initial GET pending, attempting to POST");
        try {
          await delay(2000);
          await postOrder(roomId, messageId);
        } catch (error) {
          await delay(2000);
          getResponse = await getOrder(roomId, messageId);
        }
        await delay(2000); // Adding delay before retrying
        getResponse = await getOrder(roomId, messageId);
      }
      setOrderAgodaMessage(getResponse); // Set response if POST and retry were successful
    } catch (postError) {
      console.error("POST request failed as well:", postError);
    }
  }finally {
    return result
  }
};
