import React, { useState, useEffect, createRef } from "react";
import axios from "axios";
import mqtt from "mqtt";

import { Button, message, Tooltip } from "antd";
import { useBound } from "@/contexts/BoundContext";

import { ReactComponent as LineIcon } from "../../assets/line.svg";

import Message from "../Message";
import InputLine from "../room/InputLine";

import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const MQTT_URL = "wss://api-broker.scfg.io"; // process.env.REACT_APP_MQTT_URL;
const MQTT_PORT = process.env.REACT_APP_MQTT_PORT;

const BoundChatRoom = ({ userId, token, room, client, tabs }) => {
  const {
    airbnbMessage,
    agodaMessage,
  } = useBound();

  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [initLoading, setInitLoading] = useState(true);
  const [oldestMessageId, setOldestMessageId] = useState(null);
  const [triggerScroll, setTriggerScroll] = useState(false);
  const [mqttConnection, setMqttConnection] = useState(false);
  const [reloadingBtn, setReloadingBtn] = useState(false);
  const messagesStartRef = createRef();
  const messagesEndRef = createRef();

  useEffect(() => {
    setMqttConnection(false);
    setInitLoading(true);
    setMessages([]);
    setOldestMessageId(null);
    fetchNewMessages(true).then(() => {
      setInitLoading(false);
    });

    if (client.channel === "airbnb") {
       setMessages((airbnbMessage.flatMap((obj) =>
        Object.values(obj)).filter(
          (message) => message.id === client.messageId
        ))
      );
    }else if (client.channel === "agoda") {
       setMessages((agodaMessage.flatMap((obj) =>
        Object.values(obj)).filter(
          (message) => message.id === client.messageId
        ))
      );
    }
    
  }, [room, client]);

  useEffect(() => {
    if (triggerScroll) {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      setTriggerScroll(false); // Reset scroll trigger
    }
  }, [triggerScroll]); // Depend only on the triggerScroll flag

  // MQTT connect
  useEffect(() => {
    // Establish connection with the MQTT broker
    const mqttClient = mqtt.connect(MQTT_URL, {
      clientId: "mqttx_" + Math.random().toString(16).substring(2, 10),
      port: MQTT_PORT,
      path: "/",
      connectTimeout: 10000,
      keepAlive: 30000,
      autoReconnect: true,
      reconnectPeriod: 1000,
      cleanStart: false,
    });

    mqttClient.on("error", (error) => {
      console.error("Connection error:", error);
      setMqttConnection(false);
    });

    mqttClient.on("connect", () => {
      mqttClient.subscribe(
        `chatroom/${room.id}/line/${client.userId}/#`,
        (error) => {
          if (error) {
            console.error("Subscription error:", error);
            setMqttConnection(false);
          } else {
            // console.log('Subscription successful');
            setMqttConnection(true);
          }
        }
      );
    });

    mqttClient.on("message", (topic, payload) => {
      const receivedClientId = topic.split("/")[3].toString();
      // console.log('ROOM | Message received:', receivedClientId, "content", payload.toString());

      const newMessage = JSON.parse(payload.toString());

      let ModifiedNewMessage = newMessage.question
        ? {
            ...newMessage.question,
            message: "#.aFaKeMeSsAgE",
            response: { ...newMessage, question: null },
          }
        : newMessage;

      setMessages((prevMessages) => [
        ...prevMessages,
        { ...ModifiedNewMessage },
      ]);

      setTriggerScroll(true);
    });

    // Clean up the connection when the component unmounts
    return () => mqttClient.end();
  }, [client]);

  // line fetch old messages
  const fetchOldMessages = async () => {
    try {
      const response = await axios.get(
        `${SERVER_URL}/private/property/chatroom/line_channel/${room.id}/chats/${client.userId}`,
        {
          params: {
            from_id: oldestMessageId,
            limit: 10,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Combine the current messages with the new response data
      const combinedMessages = [...messages, ...response.data];

      // Remove duplicates
      const uniqueMessages = Array.from(
        new Map(
          combinedMessages.map((message) => [message.id, message])
        ).values()
      );

      // Sort by 'created_at'
      uniqueMessages.sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      );

      setMessages(uniqueMessages);

      if (uniqueMessages.length > 0) {
        setOldestMessageId(uniqueMessages[0].id);
      }
    } catch (error) {
      console.error(error);
      // message.error(JSON.stringify(error.response?.data?.detail));
    }
  };

  // line fetch new messages
  const fetchNewMessages = async (init = false, limit = 10) => {
    try {
      const response = await axios.get(
        `${SERVER_URL}/private/property/chatroom/line_channel/${room.id}/chats/${client.userId}`,
        {
          params: {
            limit: limit,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.length > 0) {
        // Combine the current messages with the new response data
        const combinedMessages = init
          ? response.data
          : [...messages, ...response.data];

        // Remove duplicates
        const uniqueMessages = Array.from(
          new Map(
            combinedMessages.map((message) => [message.id, message])
          ).values()
        );

        // Sort by 'created_at'
        uniqueMessages.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );

        setOldestMessageId(uniqueMessages[0].id);
        setMessages(uniqueMessages);
      }
      setTriggerScroll(true); // Trigger scroll to bottom
    } catch (error) {
    }
  };

  // agoda fetch new messages
  const fetchAgodaNewMessages = async (init = false, limit = 10) => {
    try {
      const response = await axios.get(
        `https://api.scfg.io/private/module/rpa/agoda/message/${room.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
          params: {
            message_id: client.messageId,
          },
        }
      );
      const res = processAgodaData(response.data);

      if (res.length > 0) {
        // Combine the current messages with the new response data
        const combinedMessages = init ? res : [...messages, ...res];

        // Remove duplicates
        const uniqueMessages = Array.from(
          new Map(
            combinedMessages.map((message) => [message.id, message])
          ).values()
        );

        // Sort by 'created_at'
        uniqueMessages.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );

        setOldestMessageId(uniqueMessages[0].id);
        setMessages(res);
      }
      setTriggerScroll(true); // Trigger scroll to bottom
      setReloadingBtn(false);
    } catch (error) {
      // console.log('err');
      setReloadingBtn(true);
    }
  };

  // agoda data processing
  const processAgodaData = (rawData) => {
    if (!rawData || !rawData.result || rawData.result.length === 0) {
      return [];
    }

    const result = rawData.result;

    // store all processed data
    const processedDataArray = [];

    // forEach to iterate all messages
    result.forEach((messageItem) => {
      const isReservationMessage =
        messageItem.sender_role && messageItem.sender_role.includes("guest");
      if (messageItem.body && messageItem.body.trim() !== "") {
        const processedData = {
          id: rawData.last_update_timestamp,
          channel_type: "agoda",
          channel_id: "some-channel-id",
          client_id: "some-client-id",
          created_at: `${messageItem.send_date}`,
          message: isReservationMessage ? messageItem.body : "",
          user_id: "some-user-id",
          user_nickname: messageItem.sender || "Unknown",
          user_image_url: messageItem.sender_photo_url || null,
          blobs: [],

          response: {
            id: "response-id",
            channel_type: "airbnb",
            channel_id: "some-channel-id",
            created_at: `${messageItem.send_date}`,
            succeed: !isReservationMessage,
            message: !isReservationMessage ? messageItem.body : "", 
            blobs: [],
            tokens: 0,
            costs: 0,
          },
        };
        if (
          (processedData.message && processedData.message.trim() !== "") ||
          (processedData.response.message &&
            processedData.response.message.trim() !== "")
        ) {
          processedDataArray.push(processedData);
        }
      }
    });

    return processedDataArray;
  };

  // airbnb fetch new messages
  const fetchAirbnbNewMessages = async (init = false, limit = 10) => {
    try {
      const response = await axios.get(
        `https://api.scfg.io/private/module/rpa/airbnb/message/${room.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
          params: {
            message_id: client.messageId,
          },
        }
      );
      const res = processAirbnbData(response.data);

      if (res.length > 0) {
        // Combine the current messages with the new response data
        const combinedMessages = init ? res : [...messages, ...res];

        // Remove duplicates
        const uniqueMessages = Array.from(
          new Map(
            combinedMessages.map((message) => [message.id, message])
          ).values()
        );

        // Sort by 'created_at'
        uniqueMessages.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );

        setOldestMessageId(uniqueMessages[0].id);
        setMessages(res);
      }
      setTriggerScroll(true); // Trigger scroll to bottom
      setReloadingBtn(false);
    } catch (error) {
      // console.log('err');
      setReloadingBtn(true);
    }
  };

  // airbnb data processing
  const processAirbnbData = (rawData) => {
    if (!rawData || !rawData.result || rawData.result.length === 0) {
      return [];
    }

    const result = rawData.result;
    const processedDataArray = [];
    result.forEach((messageItem) => {
      const isReservationMessage =
        messageItem.sender && messageItem.sender.includes("預訂人");
      if (messageItem.content && messageItem.content.trim() !== "") {
        const processedData = {
          id: rawData.last_update_timestamp,
          channel_type: "airbnb",
          channel_id: "some-channel-id",
          client_id: "some-client-id",
          created_at: `${messageItem.time}`,
          message: isReservationMessage ? messageItem.content : "",
          user_id: "some-user-id",
          user_nickname: messageItem.sender || "Unknown",
          user_image_url: messageItem.sender_photo_url || null,
          blobs: [],

          response: {
            id: "response-id",
            channel_type: "airbnb",
            channel_id: "some-channel-id",
            created_at: `${messageItem.time}`,
            succeed: !isReservationMessage, 
            message: !isReservationMessage ? messageItem.content : "", 
            blobs: [],
            tokens: 0,
            costs: 0,
          },
        };
        if (
          (processedData.message && processedData.message.trim() !== "") ||
          (processedData.response.message &&
            processedData.response.message.trim() !== "")
        ) {
          processedDataArray.push(processedData);
        }
      }
    });

    return processedDataArray;
  };

  // line send message
  const submit = async ({ msg = null, imgBlobs = null, audioMsgId = null }) => {
    setLoading(true);

    const headers = {
      accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const baseUrl = `${SERVER_URL}/private/property/chatroom/line_channel/${room.id}/p2p/${client.userId}`;

    try {
      if (audioMsgId) {
        await axios.post(
          `${baseUrl}/speech`,
          {},
          { headers, params: { blob_id: audioMsgId } }
        );
      } else if (msg) {
        await axios.post(`${baseUrl}`, { text: msg }, { headers });
      }
    } catch (error) {
      console.error(error);
      if (error.response?.status !== 404) {
        const detail = error.response?.data?.detail;
        if (detail === "Client has not been active for 7 days") {
          message.error(
            "無法主動傳送訊息給逾 7 日未活動之客戶 (Line 系統限制)"
          );
        } else {
          message.error(JSON.stringify(detail));
        }
      }
    } finally {
      setLoading(false);
      await fetchNewMessages();
      setTriggerScroll(true);
    }
  };

  // agoda send message
  const submitAgoda = async (msg, blobs) => {
    setLoading(true);
    try {
      await axios.post(
        `${SERVER_URL}/private/module/rpa/agoda/send_message/${room.id}`,
        {
          text: msg,
        },
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          params: {
            message_id: client.messageId,
          },
        }
      );
    } catch (error) {
      console.error(error);
      // if 404 not show
      if (error.response?.status !== 404) {
        if (
          error.response?.data?.detail ===
          "Client has not been active for 7 days"
        ) {
          message.error(
            "無法主動傳送訊息給逾 7 日未活動之客戶 (Airbnb 系統限制)"
          );
        } else {
          message.error(JSON.stringify(error.response?.data?.detail));
        }
      }
    } finally {
      setLoading(false);
      await new Promise((resolve) => setTimeout(resolve, 2000)); 
      await fetchAirbnbNewMessages();
      await fetchAgodaNewMessages();
      setTriggerScroll(true);
    }
  };

  // airbnb send message
  const submitAirbnb = async (msg, blobs) => {
    setLoading(true);
    try {
      await axios.post(
        `${SERVER_URL}/private/module/rpa/airbnb/send_message/${room.id}`,
        {
          text: msg,
        },
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          params: {
            message_id: client.messageId,
          },
        }
      );
    } catch (error) {
      console.error(error);
      // if 404 not show
      if (error.response?.status !== 404) {
        if (
          error.response?.data?.detail ===
          "Client has not been active for 7 days"
        ) {
          message.error(
            "無法主動傳送訊息給逾 7 日未活動之客戶 (Airbnb 系統限制)"
          );
        } else {
          message.error(JSON.stringify(error.response?.data?.detail));
        }
      }
    } finally {
      setLoading(false);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      await fetchAirbnbNewMessages();
      await fetchAgodaNewMessages();
      setTriggerScroll(true);
    }
  };


  useEffect(() => {
    if (messages.length === 0) {
      return;
    }
  
    const scrollToBottom = () => {
      const scrollableElement = document.querySelector(".overflow-y-auto");
      if (scrollableElement) {
        scrollableElement.scrollTo({
          top: scrollableElement.scrollHeight,
          behavior: "smooth", 
        });
      }
    };
  
    setTimeout(() => {
      scrollToBottom();
    }, 300);
  }, [messages]);
  


  // check if client is available
  if (!client || !room) {
    return (
      <div className="flex justify-center items-center w-full h-screen">
        啊！發生了一點錯誤，請重新選擇或重新整理。
      </div>
    );
  }

  // initial loading
  if (initLoading) {
    return (
      <div className="flex justify-center items-center w-full h-screen">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </div>
    );
  }


  return (
    <div className="w-full h-full bg-bgLight border-l border-r flex flex-col">
      <div className="flex flex-row items-center justify-start w-full h-10 py-2 border-b">
        {/* TODO: more platform
          client.platform === 'line' && <LineIcon style={{ width: "32px", height: "32px" }} />
        */}
        <LineIcon style={{ width: "32px", height: "32px" }} />
        <p className="font-bold">{client.displayName}</p>
        {/* <ReloadOutlined
          className="hover:text-primary mr-2"
          style={{ width: "12px", height: "12px" }}
          onClick={fetchNewMessages}
        /> */}
        {mqttConnection ? (
          <Tooltip title="連線穩定">
            <div className="flex flex-row gap-1 ml-2 bg-green-600 w-2 h-2 rounded-full" />
          </Tooltip>
        ) : (
          <Tooltip title="重新連線中...點擊以重整">
            <div
              className="flex flex-row gap-1 ml-2 bg-yellow-600 w-2 h-2 rounded-full animate-ping"
              onClick={
                tabs === 0
                  ? fetchAirbnbNewMessages
                  : tabs === 1
                  ? fetchNewMessages
                  : fetchAgodaNewMessages
              }
            />
          </Tooltip>
        )}
      </div>
      <div className=" flex-grow py-2 px-4  overflow-y-auto">
        <Button
          ref={messagesStartRef}
          className="w-full my-2"
          type="text"
          onClick={fetchOldMessages}
        >
          讀取更多訊息
        </Button>
        {messages
          .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
          .map((message) => {
            //  message sent from real human using Teamsync (use AI's role)
            if (message.message === "#.aFaKeMeSsAgE") {
              return (
                message.response && message.response.blobs?.length > 0 ? (
                  message.response.blobs.map((blob, index) => (
                    <Message
                      roomId={room.id}
                      key={blob.id + Math.random().toString(16).substring(2, 6)}
                      type={"self"}
                      message={{
                        blob: blob.url,
                        blobType: blob.content_type,
                        time: blob.created_at,
                        name: client.displayName,
                        avatar: client.pictureUrl,
                      }}
                    />
                  ))
                ) : (
                  <Message
                    roomId={room.id}
                    key={
                      message.id + Math.random().toString(16).substring(2, 6)
                    }
                    type={"self"}
                    message={{
                      text: message.response.message,
                      time: message.response.created_at,
                    }}
                  />
                )
              );
            }
            
              // AI chat 
            
            return (
              <div
                key={message.id + Math.random().toString(16).substring(2, 6)}
              >
                {/* client sent blobs */}
                {message.blobs?.length > 0 &&
                  message.blobs.map((blob, index) => (
                    <Message
                      roomId={room.id}
                      key={blob.id + Math.random().toString(16).substring(2, 6)}
                      type={"other"}
                      message={{
                        blob: blob.url,
                        blobType: blob.content_type,
                        time: message.created_at,
                        name: client.displayName,
                        avatar: client.pictureUrl,
                      }}
                    />
                  ))}
                {/* client sent text */}
                {message.message && (
                  <Message
                    roomId={room.id}
                    type={"other"}
                    message={{
                      text: message.message,
                      time: message.created_at,
                      name: client.displayName,
                      avatar: client.pictureUrl,
                    }}
                  />
                )}

                {/* ai auto reply's blobs */}
                {message.response &&
                  message.response.blobs?.length > 0 &&
                  message.response.blobs.map((blob, index) => (
                    <Message
                      roomId={room.id}
                      key={blob.id + Math.random().toString(16).substring(2, 6)}
                      type={"self"}
                      message={{
                        blob: blob.url,
                        blobType: blob.content_type,
                        time: message.response.created_at,
                      }}
                    />
                  ))}
                {/* ai auto reply (vanilla fetch) */}
                {message.response &&
                  message.response.message &&
                  message.response.message !== "#.aFaKeMeSsAgE" && (
                    <Message
                      roomId={room.id}
                      type={"self"}
                      message={{
                        text: message.response.message,
                        time: message.response.created_at,
                        // question: message.message,
                      }}
                    />
                  )}
              </div>
            );
          })}
        <div ref={messagesEndRef} />
      </div>
      <InputLine
        isAI={false}
        submit={tabs === 0 ? submitAirbnb : submit}
        loading={loading}
        id={room.id}
        isBound={true}
        isTextToSpeech={tabs !== 0}
        disabled={userId !== room.creator_id}
      />
    </div>
  );
};

export default BoundChatRoom;
